import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import Login from '../pages/login/Login';
import { useAuth } from '../hooks/auth';
import Home from '../pages/home/Home';
import { useCallback, useEffect, useState } from 'react';
import { useSessionStorage } from '../services/useSessionStorage';
import IUser from '../interfaces/IUser';
import { user } from '@seniorsistemas/senior-platform-data';
import { Loading } from '../pages/loading/Loading';
import { ImageParamsList } from '../pages/image-params-list/ImageParamsList';
import { ImageParams } from '../pages/image-params/ImageParams';
import Order from '../pages/order';
import { OrdersList } from '../pages/orders-list/OrdersList';
import CustomerList from '../pages/customer-list/CustomerList';
import { ProductList } from '../pages/product-list/ProductList';
import OrderStatusList from '../pages/order-status-list/OrderStatusList';
import { OrderTypesList } from '../pages/order-types-list/OrderTypesList';
import OrderForm from '../pages/order-form/OrderForm';
import OrderSteper from '../pages/order-form/OrderSteper';
import { OrderTypes } from '../pages/order-types/OrderTypes';
import { SellersList } from '../pages/sellers-list/SellersList';
import OrderParamsForm from '../pages/order-params/OrderParamsForm';
import MeasurementUnitList from '../pages/measurement-unit/MeasurementUnitList';
import { PriceTable } from '../pages/price-table/PriceTable';
import { PriceTablesList } from '../pages/price-tables-list/PriceTablesList';
import CustomerDetails from '../pages/customer-details/CustomerDetails';
import { PriceTableItem } from '../pages/price-table-item/PriceTableItem';
import ConfigureApplications from '../pages/configure-applications/ConfigureApplications';
import ConfigureApplication from '../pages/configure-application/ConfigureApplication';
import { FieldConfigurationsList } from '../pages/field-configurations-list /FieldConfigurationsList';

const AppRouter = () => {
  const { state: userState, setState: setUserState } = useAuth();
  const sessionStorage = useSessionStorage();

  const [loading, setLoading] = useState(false);

  const location = useLocation();

  const handleAuthenticatePlatform = useCallback(async () => {
    setLoading(true);
    if (location.pathname === '/') {
      setLoading(false);
    } else {
      await user
        .getToken()
        .then(token => {
          const userState: IUser = {
            access_token: token.access_token,
            email: token.email,
            expires_in: token.expires_in,
            logged: true,
            name: token.fullName?.replaceAll('+', ' '),
            refresh_token: token.refresh_token,
            tenantDomain: token.tenantDomain,
            username: token.username,
            typeAuth: 'platform',
            seller: token.seller,
            resources: token.resources,
          };
          sessionStorage.setItem('user', userState);
          setUserState({ ...userState });
        })
        .catch(() => {})
        .finally(() => {
          setLoading(false);
        });
    }
  }, []);

  useEffect(() => {
    handleAuthenticatePlatform();
  }, []);

  return (
    <Routes>
      <Route path="/order/:id/report" element={<Order title="Pedidos WEB" />} />
      <Route path="/order/:id/report/pdf" element={<Order title="Pedidos WEB" />} />

      {!userState.access_token && (
        <>
          {loading ? (
            <Route path="*" element={<Loading title="Pedidos WEB" />} />
          ) : (
            <>
              <Route path="/" element={<Login title="Pedidos WEB | Login" />} />
              <Route path="*" element={<Navigate to="/" />} />
            </>
          )}
        </>
      )}
      {userState.access_token && (
        <>
          {loading ? (
            <Route path="*" element={<Loading title="Pedidos WEB" />} />
          ) : (
            <>
              <Route path="/" element={<Home title="Pedidos WEB | Home" />} />
              {userState.resources.some(item => item === 'configurações-parametros-pedido-web') && (
                <Route path="/order-params" element={<OrderParamsForm title="Pedidos WEB | Parâmetro do Pedido" />} />
              )}
              {userState.resources.some(item => item === 'configurações-imagens-pedido-web') && (
                <>
                  <Route
                    path="/image-params-list"
                    element={<ImageParamsList title="Pedidos WEB | Parâmetros das Imagens" />}
                  />
                  <Route path="/image-params" element={<ImageParams title="Pedidos WEB | Parâmetros da Imagem" />} />
                  <Route
                    path="/image-params/:id"
                    element={<ImageParams title="Pedidos WEB | Parâmetros da Imagem" />}
                  />
                </>
              )}
              {userState.resources.some(item => item === 'configurações-status-pedido-web') && (
                <Route path="/orders-status-list" element={<OrderStatusList title="Pedidos WEB | Status Pedidos " />} />
              )}
              {userState.resources.some(item => item === 'configurações-tipos-pedido-web') && (
                <>
                  <Route path="/order-types-list" element={<OrderTypesList title="Pedidos WEB | Tipos de Pedido " />} />
                  <Route path="/order-types/:id/new" element={<OrderTypes title="Pedidos WEB | Tipos de Pedido" />} />
                  <Route path="/order-types/:id/edit" element={<OrderTypes title="Pedidos WEB | Tipos de Pedido" />} />
                </>
              )}
              {userState.resources.some(item => item === 'configurações-status-pedido-web') && (
                <Route
                  path="/field-configurations-list"
                  element={<FieldConfigurationsList title="Pedidos WEB | Configurações dos Campos " />}
                />
              )}
              {userState.resources.some(item => item === 'consulta-produtos-pedido-web') && (
                <Route path="/products-list" element={<ProductList title="Pedidos WEB | Produtos " />} />
              )}
              {userState.resources.some(item => item === 'consulta-unidades-medida-pedido-web') && (
                <Route
                  path="/measurement-unit"
                  element={<MeasurementUnitList title="Pedidos WEB | Unidades de Medida " />}
                />
              )}
              {userState.resources.some(item => item === 'consulta-representantes-pedido-web') && (
                <Route path="/sellers-list" element={<SellersList title="Pedidos WEB | Representantes" />} />
              )}

              {userState.resources.some(item => item === 'consulta-clientes-pedido-web') && (
                <Route path="/customer-details/:id" element={<CustomerDetails title="Pedidos WEB | Cliente " />} />
              )}

              {userState.resources.some(item => item === 'consulta-clientes-pedido-web') && (
                <Route path="/customer-details" element={<CustomerDetails title="Pedidos WEB | Cliente " />} />
              )}

              {userState.resources.some(item => item === 'consulta-clientes-pedido-web') && (
                <Route path="/customers-list" element={<CustomerList title="Pedidos WEB | Clientes " />} />
              )}
              {userState.resources.some(item => item === 'consulta-pedidos-pedido-web') && (
                <Route path="/orders-list" element={<OrdersList title="Pedidos WEB | Pedidos " />} />
              )}

              {userState.resources.some(item => item === 'consulta-table-preco-pedido-web') && (
                <Route path="/price-table/:id" element={<PriceTable title="Pedidos WEB | Tabela de Preço " />} />
              )}

              {userState.resources.some(item => item === 'consulta-table-preco-pedido-web') && (
                <Route path="/price-table" element={<PriceTable title="Pedidos WEB | Tabela de Preço " />} />
              )}

              {userState.resources.some(item => item === 'consulta-table-preco-pedido-web') && (
                <Route
                  path="/price-tables-list"
                  element={<PriceTablesList title="Pedidos WEB | Tabela de Preços " />}
                />
              )}

              {userState.resources.some(item => item === 'consulta-table-preco-pedido-web') && (
                <Route
                  path="/price-table-item/:id"
                  element={<PriceTableItem title="Pedidos WEB | Tabela de Preço - Item " />}
                />
              )}

              {userState.resources.some(item => item === 'editar-pedidos-pedido-web') && (
                <>
                  <Route path="/order/steper/:id/new" element={<OrderSteper title="Pedidos WEB | Novo Pedido " />} />
                  <Route path="/order/steper/:id/edit" element={<OrderSteper title="Pedidos WEB | Novo Pedido " />} />
                  <Route path="/order/:id/edit" element={<OrderForm title="Pedidos WEB | Editar Pedido " />} />
                </>
              )}

              {userState.resources.some(item => item === 'configurações-aplicações-pedido-web') && (
                <>
                  <Route
                    path="/configure-applications"
                    element={<ConfigureApplications title="Pedidos WEB | Configuração de Aplicações" />}
                  />

                  <Route
                    path="/configure-application"
                    element={<ConfigureApplication title="Pedidos WEB | Configuração de Aplicação" />}
                  />

                  <Route
                    path="/configure-application/:id"
                    element={<ConfigureApplication title="Pedidos WEB | Configuração de Aplicação" />}
                  />
                </>
              )}
              <Route path="*" element={<Navigate to="/" />} />
            </>
          )}
        </>
      )}
    </Routes>
  );
};

export default AppRouter;
