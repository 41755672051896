import { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Dialog, IconButton, TableBody, TableHead, TableRow, Tooltip } from '@mui/material';
import { AiTwotoneEdit } from 'react-icons/ai';
import { FiTrash, FiPlusCircle } from 'react-icons/fi';
import { Button } from '../../components/button/Button';

import {
  TableBodyCard,
  TableCard,
  TableCardRow,
  TableItems,
  TableCellCustom,
  TableCardTitle,
  TableCardDescription,
  IconButtonAdd,
  RemoveContent,
  RemoveContentActions,
  TotalsContainer,
  TotalsValues,
  OrderItemListContainer,
  ButtonSaveContainer,
} from './OrderItemList.style';
import { IOrderItem } from '../../interfaces/IOrderItem';
import CustomTableSortLabel from '../../components/table/CustomTableSortLabel/CustomTableSortLabel';
import { useOrderItemService } from '../../services/useOrderItemService';
import { OrdemItemEdit } from './OrderItemEdit';
import { useToastr } from '../../hooks/useToastr';
import SocketioService from '../../SocketIoService';
import { useAuth } from '../../hooks/auth';
import { IOrder } from '../../interfaces/IOrder';
import { useOrderService } from '../../services/useOrderService';
import IOrderItemListProps from './interfaces/IOrderItemListProps';
import { BackdropCustom } from '../../components/backdrop/Backdrop';

interface IParams {
  itemsUpdated?(): void;
}

const OrderItemList = forwardRef<IOrderItemListProps, IParams>((props, ref) => {
  const [items, setItems] = useState<IOrderItem[]>([]);
  const [itemId, setItemId] = useState<string | null>(null);

  const [perPage] = useState(200);
  const [currentPage] = useState(1);
  const [orderDirection, setOrderDirection] = useState<'asc' | 'desc'>('asc');
  const [orderField, setOrderField] = useState<string>('sequence');
  const [loading, setLoading] = useState(false);

  const [openDialog, setOpenDialog] = useState(false);
  const [order, setOrder] = useState<IOrder | null>(null);
  const [isRemoveDialogOpen, setIsRemoveDialogOpen] = useState<boolean>(false);

  const params = useParams();
  const location = useLocation();

  const [isSteper] = useState(location.pathname.split('/').indexOf('steper') !== -1);

  const [orderId] = useState(params.id);

  const { listItemsByOrderId } = useOrderItemService();
  const orderItemService = useOrderItemService();
  const orderService = useOrderService();
  const toastr = useToastr();
  const navigate = useNavigate();

  const socketIoService = new SocketioService();

  const { state: user } = useAuth();

  useImperativeHandle(ref, () => ({
    listOrder: loadOrder,
    async finish() {
      const thereAreItemsWithErrors = items.filter(item => item.status?.id !== 2)?.length !== 0;
      const thereAreItems = items.length !== 0;

      if (thereAreItemsWithErrors) {
        toastr.error('Existem itens com erros de cálculo.');
        return;
      }
      if (!thereAreItems) {
        toastr.error('Não é possível finalizar pedido sem itens.');
        return;
      }
      navigate(`/order/${params.id}/edit`);
    },

    addItem() {
      handleToEdit(null);
    },
  }));

  const handleCloseDialog = () => {
    setOpenDialog(!openDialog);
    if (openDialog) {
      handleListItems();
      setItemId(null);
    }
  };

  const handleRemoveIconClicked = (id: string) => {
    setItemId(id);
    setIsRemoveDialogOpen(true);
  };

  const handleDenyToRemove = () => {
    setItemId(null);
    setIsRemoveDialogOpen(false);
  };

  const loadOrder = async (): Promise<void> => {
    setLoading(true);
    await orderService
      .listOrder(`${orderId}`, true)
      .then(order => {
        setOrder(order);
      })
      .catch(error => {
        toastr.error(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleRemoveItem = async (): Promise<void> => {
    setLoading(true);
    orderItemService
      .remove(`${itemId}`)
      .then(async () => {
        props.itemsUpdated?.();
        setIsRemoveDialogOpen(false);
        toastr.success('Item excluído com sucesso!');
        await handleListItems();
        await loadOrder();
      })
      .catch(error => {
        toastr.error(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleToEdit = async (id: string | null) => {
    setItemId(id);
    handleCloseDialog();
  };

  const handleListItems = useCallback(async () => {
    if (params.id) {
      setLoading(true);
      await listItemsByOrderId(
        params.id,
        `perPage=${perPage}&currentPage=${currentPage}&orderBy=${orderField}&orderDirection=${orderDirection}`,
      )
        .then(response => {
          setItems(response.data);
        })
        .catch(() => {})
        .finally(() => {
          setLoading(false);
        });
    }
  }, [params.id]);

  useEffect(() => {
    handleListItems();

    socketIoService.connect(user);
    socketIoService.subscribeToCalculateItem(updatedItem => {
      loadOrder();
      props.itemsUpdated?.();
      setItems(state => {
        return state.map(item => {
          return item.id === updatedItem.id ? updatedItem : item;
        });
      });
    });
    return () => {
      socketIoService.disconnect();
    };
  }, []);

  useEffect(() => {
    loadOrder();
  }, [orderId]);

  const formatCurrency = (value: number): string => {
    return new Intl.NumberFormat('pt-br', { style: 'currency', currency: 'BRL' }).format(value);
  };

  const formatCurrencyNumber = (value: number, precision: number | undefined): string => {
    return new Intl.NumberFormat('pt-br', { minimumFractionDigits: precision || 0 }).format(value);
  };

  const isEditionDisabled = () => {
    return order?.orderStatus?.id !== 1 || loading || Number(order?.orderNumber) > 0;
  };

  return (
    <OrderItemListContainer>
      <Dialog open={isRemoveDialogOpen}>
        <RemoveContent>
          <h3>Confirma a exclusão do ítem?</h3>
          <RemoveContentActions>
            <Button variant="outlined" color="inherit" onClick={handleDenyToRemove}>
              Não
            </Button>
            <Button variant="outlined" color="error" onClick={handleRemoveItem}>
              Sim
            </Button>
          </RemoveContentActions>
        </RemoveContent>
      </Dialog>
      {orderId && (
        <OrdemItemEdit
          openDialog={openDialog}
          orderId={orderId}
          id={itemId || undefined}
          handleClose={handleCloseDialog}
        />
      )}
      {loading && <BackdropCustom />}

      <IconButtonAdd onClick={() => handleToEdit(null)} disabled={isEditionDisabled()}>
        <FiPlusCircle size={50} />
      </IconButtonAdd>
      {isSteper && (
        <ButtonSaveContainer>
          <Button variant="contained" onClick={() => handleToEdit(null)}>
            Adicionar Itens
          </Button>
        </ButtonSaveContainer>
      )}

      <TableItems>
        <TableHead>
          <TableRow
            sx={{
              '&:last-child td, &:last-child th': {
                borderBottom: 1,
                borderColor: '#E5E5E5',
              },
            }}
          >
            <TableCellCustom size="small" style={{ minWidth: '80px', textAlign: 'center' }}>
              Ações
            </TableCellCustom>

            <CustomTableSortLabel
              perPage={perPage}
              currentPage={currentPage}
              orderField={orderField}
              field="sequence"
              label="Seq."
              handleRequest={handleListItems}
              orderDirection={orderDirection}
              disableOrder
              setField={setOrderField}
              setDirection={setOrderDirection}
            />

            <CustomTableSortLabel
              perPage={perPage}
              currentPage={currentPage}
              orderField={orderField}
              field="description"
              label="Descrição"
              handleRequest={handleListItems}
              orderDirection={orderDirection}
              setField={setOrderField}
              setDirection={setOrderDirection}
              disableOrder
            />
            <CustomTableSortLabel
              perPage={perPage}
              currentPage={currentPage}
              orderField={orderField}
              field="sku"
              label="Produto"
              handleRequest={handleListItems}
              orderDirection={orderDirection}
              setField={setOrderField}
              setDirection={setOrderDirection}
              disableOrder
            />
            <CustomTableSortLabel
              perPage={perPage}
              currentPage={currentPage}
              orderField={orderField}
              field="quantity"
              label="Qtd."
              handleRequest={handleListItems}
              orderDirection={orderDirection}
              setField={setOrderField}
              setDirection={setOrderDirection}
              disableOrder
            />
            <CustomTableSortLabel
              perPage={perPage}
              currentPage={currentPage}
              orderField={orderField}
              field="price"
              label="Pre. Uni."
              handleRequest={handleListItems}
              orderDirection={orderDirection}
              setField={setOrderField}
              setDirection={setOrderDirection}
              disableOrder
            />
            <CustomTableSortLabel
              perPage={perPage}
              currentPage={currentPage}
              orderField={orderField}
              field="price"
              label="Uni. c/ Imp."
              handleRequest={handleListItems}
              orderDirection={orderDirection}
              setField={setOrderField}
              setDirection={setOrderDirection}
              disableOrder
            />
            <CustomTableSortLabel
              perPage={perPage}
              currentPage={currentPage}
              orderField={orderField}
              field="IPI_Value"
              label="IPI"
              handleRequest={handleListItems}
              orderDirection={orderDirection}
              setField={setOrderField}
              setDirection={setOrderDirection}
              disableOrder
            />
            <CustomTableSortLabel
              perPage={perPage}
              currentPage={currentPage}
              orderField={orderField}
              field="ICMSST_Value"
              label="ICMS ST"
              handleRequest={handleListItems}
              orderDirection={orderDirection}
              disableOrder
              setField={setOrderField}
              setDirection={setOrderDirection}
            />

            <CustomTableSortLabel
              perPage={perPage}
              currentPage={currentPage}
              orderField={orderField}
              field="status"
              label="Situação"
              handleRequest={handleListItems}
              orderDirection={orderDirection}
              disableOrder
              setField={setOrderField}
              setDirection={setOrderDirection}
            />

            <CustomTableSortLabel
              perPage={perPage}
              currentPage={currentPage}
              orderField={orderField}
              field="amount"
              label="Valor Total"
              handleRequest={handleListItems}
              orderDirection={orderDirection}
              disableOrder
              setField={setOrderField}
              setDirection={setOrderDirection}
            />
            <CustomTableSortLabel
              perPage={perPage}
              currentPage={currentPage}
              orderField={orderField}
              field="stockQuantity"
              label="Estoque Disponível"
              handleRequest={handleListItems}
              orderDirection={orderDirection}
              disableOrder
              setField={setOrderField}
              setDirection={setOrderDirection}
            />
          </TableRow>
        </TableHead>
        <TableBody>
          {items &&
            items.map(item => {
              return (
                <TableRow
                  key={item.id}
                  sx={{
                    '&:last-child td, &:last-child th': {
                      border: 0,
                    },
                  }}
                >
                  <TableCellCustom size="small" style={{ minWidth: '60px', textAlign: 'center' }}>
                    <Tooltip title="Deletar" placement="top">
                      <span>
                        <IconButton
                          disabled={isEditionDisabled()}
                          onClick={() => handleRemoveIconClicked(`${item.id}`)}
                        >
                          <FiTrash size={15} color={order?.orderStatus?.id !== 1 || loading ? 'gray' : '#F90303'} />
                        </IconButton>
                      </span>
                    </Tooltip>

                    <Tooltip title="Editar" placement="top">
                      <span>
                        <IconButton disabled={isEditionDisabled()} onClick={() => handleToEdit(`${item.id}`)}>
                          <AiTwotoneEdit
                            size={15}
                            color={order?.orderStatus?.id !== 1 || loading ? 'gray' : '#0052BE'}
                          />
                        </IconButton>
                      </span>
                    </Tooltip>
                  </TableCellCustom>

                  <TableCellCustom size="small">{item.sequence}</TableCellCustom>
                  <TableCellCustom size="small">{item.product?.description}</TableCellCustom>
                  <TableCellCustom size="small" sx={{ whiteSpace: 'nowrap' }}>
                    {`${item.product?.sku}  ${
                      item.product?.derivation && item.product?.derivation?.length >= 1
                        ? `- ${item.product?.derivation}`
                        : ''
                    } `}
                  </TableCellCustom>

                  <TableCellCustom size="small">
                    {item.quantity > 0
                      ? formatCurrencyNumber(item.quantity, item?.product?.measurementUnit?.precision)
                      : '0,00'}
                  </TableCellCustom>

                  <TableCellCustom size="small">
                    {`R$ ${(item.price ? item.price : 0).toLocaleString('pt-BR', {
                      style: 'decimal',
                      minimumFractionDigits: 2,
                    })}`}
                  </TableCellCustom>

                  <TableCellCustom size="small">
                    {`R$ ${(item.amount && item.quantity !== 0 ? item.amount / item.quantity : 0).toLocaleString(
                      'pt-BR',
                      {
                        style: 'decimal',
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      },
                    )}`}
                  </TableCellCustom>

                  <TableCellCustom size="small" sx={{ whiteSpace: 'nowrap' }}>
                    {`R$ ${(item.IPI_Value ? item.IPI_Value : 0).toLocaleString('pt-BR', {
                      style: 'decimal',
                      minimumFractionDigits: 2,
                    })}`}
                  </TableCellCustom>

                  <TableCellCustom size="small" sx={{ whiteSpace: 'nowrap' }}>
                    {`R$ ${(item.ICMSST_Value ? item.ICMSST_Value : 0).toLocaleString('pt-BR', {
                      style: 'decimal',
                      minimumFractionDigits: 2,
                    })}`}
                  </TableCellCustom>

                  <TableCellCustom size="small" statusid={item.status?.id}>
                    <Tooltip title={item.errorMessage} placement="top">
                      <strong>{item.status?.description}</strong>
                    </Tooltip>
                  </TableCellCustom>

                  <TableCellCustom size="small" sx={{ whiteSpace: 'nowrap' }}>
                    {`R$ ${(item.amount ? item.amount : 0).toLocaleString('pt-BR', {
                      style: 'decimal',
                      minimumFractionDigits: 2,
                    })}`}
                  </TableCellCustom>
                  <TableCellCustom size="small">
                    {item.product?.stockQuantity && item.product?.stockQuantity > 0
                      ? formatCurrencyNumber(item.product?.stockQuantity, item?.product?.measurementUnit?.precision)
                      : '0,00'}
                  </TableCellCustom>
                </TableRow>
              );
            })}
        </TableBody>
      </TableItems>

      <TableBodyCard>
        {items &&
          items.map(item => {
            return (
              <TableCard key={item.id}>
                <TableCardRow>
                  <TableCardTitle>Seq.</TableCardTitle>
                  <TableCardDescription>{item.sequence}</TableCardDescription>
                </TableCardRow>
                <TableCardRow>
                  <TableCardTitle>Descrição</TableCardTitle>
                  <Tooltip title={item.product?.description} placement="top">
                    <TableCardDescription>{item.product?.description}</TableCardDescription>
                  </Tooltip>
                </TableCardRow>
                <TableCardRow>
                  <TableCardTitle>Produto</TableCardTitle>
                  <TableCardDescription>
                    {`${item.product?.sku}  ${
                      item.product?.derivation && item.product?.derivation?.length >= 1
                        ? `- ${item.product?.derivation}`
                        : ''
                    } `}
                  </TableCardDescription>
                </TableCardRow>
                <TableCardRow>
                  <TableCardTitle>Qtd.</TableCardTitle>
                  <TableCardDescription>{item.quantity}</TableCardDescription>
                </TableCardRow>
                <TableCardRow>
                  <TableCardTitle>Pre. Uni.</TableCardTitle>
                  <TableCardDescription>{`R$ ${(item.price ? item.price : 0).toLocaleString('pt-BR', {
                    style: 'decimal',
                    minimumFractionDigits: 2,
                  })}`}</TableCardDescription>
                </TableCardRow>
                <TableCardRow>
                  <TableCardTitle>Uni. c/ Imp.</TableCardTitle>
                  <TableCardDescription>
                    {`R$ ${(item.amount && item.quantity !== 0 ? item.amount / item.quantity : 0).toLocaleString(
                      'pt-BR',
                      {
                        style: 'decimal',
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      },
                    )}`}
                  </TableCardDescription>
                </TableCardRow>
                <TableCardRow>
                  <TableCardTitle>IPI</TableCardTitle>
                  <TableCardDescription>{`R$ ${(item.IPI_Value ? item.IPI_Value : 0).toLocaleString('pt-BR', {
                    style: 'decimal',
                    minimumFractionDigits: 2,
                  })}`}</TableCardDescription>
                </TableCardRow>
                <TableCardRow>
                  <TableCardTitle>ICMS ST</TableCardTitle>
                  <TableCardDescription>{`R$ ${(item.ICMSST_Value ? item.ICMSST_Value : 0).toLocaleString('pt-BR', {
                    style: 'decimal',
                    minimumFractionDigits: 2,
                  })}`}</TableCardDescription>
                </TableCardRow>

                <TableCardRow>
                  <TableCardTitle>Situação</TableCardTitle>
                  <TableCardDescription statusid={item.status?.id}>
                    <Tooltip title={item.errorMessage} placement="top" enterTouchDelay={0} leaveTouchDelay={10}>
                      <strong>{item.status?.description}</strong>
                    </Tooltip>
                  </TableCardDescription>
                </TableCardRow>

                <TableCardRow>
                  <TableCardTitle>Valor Total</TableCardTitle>
                  <TableCardDescription>{`R$ ${(item.amount ? item.amount : 0).toLocaleString('pt-BR', {
                    style: 'decimal',
                    minimumFractionDigits: 2,
                  })}`}</TableCardDescription>
                </TableCardRow>
                <TableCardRow>
                  <TableCardTitle>Ações</TableCardTitle>
                  <TableCardDescription>
                    <Tooltip title="Deletar" placement="top">
                      <span>
                        <IconButton
                          disabled={isEditionDisabled()}
                          onClick={() => handleRemoveIconClicked(`${item.id}`)}
                        >
                          <FiTrash size={15} color={order?.orderStatus?.id !== 1 || loading ? 'gray' : '#F90303'} />
                        </IconButton>
                      </span>
                    </Tooltip>

                    <Tooltip title="Editar" placement="top">
                      <span>
                        <IconButton disabled={isEditionDisabled()} onClick={() => handleToEdit(`${item.id}`)}>
                          <AiTwotoneEdit
                            size={15}
                            color={order?.orderStatus?.id !== 1 || loading ? 'gray' : '#0052BE'}
                          />
                        </IconButton>
                      </span>
                    </Tooltip>
                  </TableCardDescription>
                </TableCardRow>
              </TableCard>
            );
          })}
      </TableBodyCard>
      <hr />
      <TotalsContainer>
        <TotalsValues>
          <strong>Total Produtos:</strong>
          <div>{formatCurrency(order?.grossTotalValue || 0)}</div>
        </TotalsValues>

        <TotalsValues>
          <strong>IPI:</strong>
          <div>{formatCurrency(order?.IPI_TotalValue || 0)}</div>
        </TotalsValues>
        <TotalsValues>
          <strong>ICMS ST:</strong>
          <div>{formatCurrency(order?.ICMSST_TotalValue || 0)}</div>
        </TotalsValues>

        <TotalsValues>
          <strong>Desconto ZF:</strong>
          <div>{formatCurrency(order?.discountFTZTotalValue || 0)}</div>
        </TotalsValues>
        <TotalsValues>
          <strong>Total Pedido:</strong>
          <div>{formatCurrency(order?.totalValue || 0)}</div>
        </TotalsValues>
      </TotalsContainer>
    </OrderItemListContainer>
  );
});

export default OrderItemList;
