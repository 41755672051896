import React, { HtmlHTMLAttributes } from 'react';

import { UserContextProvider } from './auth';
import { TenantDomainContextProvider } from './TenantDomainProvider';

interface IAppProvider extends HtmlHTMLAttributes<HTMLElement> {}

const AppProvider: React.FC<IAppProvider> = ({ children }) => {
  return (
    <TenantDomainContextProvider>
      <UserContextProvider>{children}</UserContextProvider>
    </TenantDomainContextProvider>
  );
};

export { AppProvider };
