import AppError from '../errors/AppError';
import { ICustomer } from '../interfaces/ICustomer';
import { IPaginationReturn } from '../interfaces/IPaginationReturn';
import IUser from '../interfaces/IUser';

import { useRequest } from './useRequest';

const useCustomerService = () => {
  const request = useRequest<ICustomer>();
  const path = 'customers';
  const listAll = async (queryParams: string): Promise<IPaginationReturn<ICustomer[]>> => {
    return await request
      .getManyPaginated({ path: `${path}?${queryParams}`, sendAuthorization: true })
      .then(result => {
        return result;
      })
      .catch(error => {
        throw new AppError(error.message);
      });
  };
  const listDynamically = async (queryParams: string): Promise<IPaginationReturn<ICustomer[]>> => {
    const userSession = sessionStorage.getItem('@PEDIDO-WEB:user');

    const user: IUser = userSession ? JSON.parse(userSession) : null;

    const idSalesRegion = user.seller?.salesRegion?.id ? user.seller?.salesRegion?.id : null;

    return await request
      .getManyPaginated({
        path: `${path}/dynamically?${queryParams}&idSalesRegion=${idSalesRegion}`,
        sendAuthorization: true,
      })
      .then(result => {
        return result;
      })
      .catch(error => {
        throw new AppError(error.message);
      });
  };

  const listById = async (id: string): Promise<ICustomer> => {
    return await request
      .getOne({ path: `${path}/${id}`, sendAuthorization: true })
      .then(result => {
        return result;
      })
      .catch(error => {
        throw new AppError(error.message);
      });
  };

  const create = async (customer: ICustomer): Promise<ICustomer> => {
    return await request
      .post({ path, sendAuthorization: true, body: customer })
      .then(result => {
        return result;
      })
      .catch(error => {
        throw new AppError(error.message);
      });
  };

  const update = async (id: string, customer: ICustomer): Promise<ICustomer> => {
    return await request
      .put({ path: `${path}/${id}`, sendAuthorization: true, body: customer })
      .then(result => {
        return result;
      })
      .catch(error => {
        throw new AppError(error.message);
      });
  };
  return { listAll, listDynamically, listById, create, update };
};

export { useCustomerService };
