import React, { createContext, ReactNode, useContext, useState } from 'react';
import IUser from '../interfaces/IUser';

import { useSessionStorage } from '../services/useSessionStorage';
import { useLoginService } from '../services/useLoginService';
import { AppHeader } from '../components/app-header/AppHeader';
import { ICustomization } from '../interfaces/ICustomization';
import { useLocalStorage } from '../services/useLocalStorage';

type PropsUserContext = {
  state: IUser;
  customization: ICustomization;
  setState: React.Dispatch<React.SetStateAction<IUser>>;
  signIn(username: string, password: string): Promise<void>;
  signOut(): void;
};

const UserContext = createContext<PropsUserContext>({} as PropsUserContext);

const UserContextProvider = ({ children }: { children: ReactNode }) => {
  const sessionStorage = useSessionStorage();
  const localStorage = useLocalStorage();

  const userLoginService = useLoginService();

  const user = sessionStorage.getItem('@PEDIDO-WEB:user');
  const customizationStorage = localStorage.getItem('@PEDIDO-WEB:customization');

  const setTheme = () => {
    const customization: ICustomization = localStorage.getItem('@PEDIDO-WEB:customization');

    if (customization) {
      document.documentElement.style.setProperty('--main-color', `${customization.mainColor}` || '#088671');
      document.documentElement.style.setProperty('--hover-color', `${customization.hoverColor}` || '#08816d');
    }
  };

  const {
    id,
    expires_in,
    username,
    access_token,
    refresh_token,
    name,
    email,
    tenantDomain,
    logged,
    typeAuth,
    seller,
    resources,
  } = user || {
    expires_in: 0,
    username: '',
    access_token: '',
    refresh_token: '',
    name: '',
    email: '',
    tenantDomain: '',
    logged: false,
    typeAuth: '',
    seller: null,
    resources: [],
  };

  const [state, setState] = useState<IUser>({
    id,
    expires_in,
    username,
    access_token,
    refresh_token,
    name,
    email,
    tenantDomain,
    logged,
    typeAuth,
    seller,
    resources,
  });

  const { fontColor, hoverColor, mainColor, logoHeader, logoLogin, iFrame } = customizationStorage || {
    fontColor: '',
    hoverColor: '',
    mainColor: '',
    logoHeader: '',
    logoLogin: '',
    iFrame: '',
  };

  const [customization, setCustomization] = useState<ICustomization>({
    fontColor,
    hoverColor,
    mainColor,
    logoHeader,
    logoLogin,
    iFrame,
  });

  const signIn = async (username: string, password: string) => {
    const user = await userLoginService.login(username, password);
    setState(user);

    user.customization && setCustomization(user.customization);
    localStorage.setItem('@PEDIDO-WEB:customization', user.customization);
    delete user.customization;
    sessionStorage.setItem('@PEDIDO-WEB:user', user);

    setTheme();
  };

  const signOut = async () => {
    sessionStorage.removeItem('@PEDIDO-WEB:user');
    setState({} as IUser);
  };

  return (
    <UserContext.Provider
      value={{
        state,
        customization,
        setState,
        signIn,
        signOut,
      }}
    >
      {user && <AppHeader />}
      {children}
    </UserContext.Provider>
  );
};

function useAuth(): PropsUserContext {
  const context = useContext(UserContext);

  if (!context) {
    throw new Error('useAuth deve ser usado dentro de um AuthProvider');
  }

  return context;
}

export { UserContextProvider, useAuth };
