import AppError from '../errors/AppError';
import { IActiveImages } from '../interfaces/IActiveImages';
import { IOrder } from '../interfaces/IOrder';
import IOrderParam from '../interfaces/IOrderParam';
import { useRequest } from './useRequest';

const useOpenedRoutesService = () => {
  const pdfRequest = useRequest<string>();
  const orderRequest = useRequest<IOrder>();
  const activeImagesRequest = useRequest<IActiveImages>();
  const path = 'opened';

  const listOrder = async (id: string, tenantDomain: string): Promise<IOrder> => {
    return await orderRequest
      .getOne({
        path: `${path}/orders/${id}`,
        sendAuthorization: false,
        headers: { tenant: tenantDomain },
      })
      .then(result => {
        return result;
      })
      .catch(error => {
        throw new AppError(error.message, error.status);
      });
  };

  const listActiveImages = async (tenantDomain: string): Promise<IActiveImages> => {
    return await activeImagesRequest
      .getOne({
        path: `${path}/active_images`,
        sendAuthorization: false,
        headers: { tenant: tenantDomain },
      })
      .then(result => {
        return result;
      })
      .catch(error => {
        throw new AppError(error.message, error.status);
      });
  };

  const getOrderPDF = async (id: string, tenantDomain: string): Promise<string> => {
    const pdf = await pdfRequest
      .getOne({
        path: `${path}/orders/report/${id}`,
        sendAuthorization: false,
        headers: { tenant: tenantDomain },
      })
      .then(result => {
        return result;
      })
      .catch(error => {
        throw new AppError(error.message, error.status);
      });

    return pdf;
  };

  const listOrderParam = async (tenantDomain: string): Promise<IOrderParam> => {
    return await orderRequest
      .getOne({
        path: `${path}/order-params`,
        sendAuthorization: false,
        headers: { tenant: tenantDomain },
      })
      .then(result => {
        return result;
      })
      .catch(error => {
        throw new AppError(error.message, error.status);
      });
  };

  return { listOrder, listActiveImages, getOrderPDF, listOrderParam };
};

export { useOpenedRoutesService };
