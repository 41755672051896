import Content from '../components/content/Content';
import { HashRouter } from 'react-router-dom';
import { AppProvider } from '../hooks/AppProvider';
import { useMemo, useState } from 'react';
import * as locales from '@mui/material/locale';
import { createTheme, ThemeProvider, useTheme } from '@mui/material/styles';
import { useLocalStorage } from '../services/useLocalStorage';

type SupportedLocales = keyof typeof locales;

function App() {
  const [locale] = useState<SupportedLocales>('ptBR');

  const theme = useTheme();

  const themeWithLocale = useMemo(
    () => createTheme({ ...theme, typography: { fontSize: 12 } }, locales[locale]),
    [locale, theme],
  );

  const localStorage = useLocalStorage();

  const customization = localStorage.getItem('@PEDIDO-WEB:customization');

  if (customization) {
    document.documentElement.style.setProperty('--main-color', `${customization.mainColor}` || '#088671');
    document.documentElement.style.setProperty('--hover-color', `${customization.hoverColor}` || '#08816d');
  }

  return (
    <HashRouter>
      <ThemeProvider theme={themeWithLocale}>
        <AppProvider>
          <Content />
        </AppProvider>
      </ThemeProvider>
    </HashRouter>
  );
}

export default App;
