import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { ICustomer } from '../../interfaces/ICustomer';
import { useCustomerService } from '../../services/useCustomerService';
import { useToastr } from '../../hooks/useToastr';
import IFormError from '../../interfaces/IFormError';
import { useValidateSchema } from '../../services/useValidateSchema';
import * as yup from 'yup';
import { IOrder } from '../../interfaces/IOrder';
import { useOrderService } from '../../services/useOrderService';
import AsyncSearch, { IOption } from '../../components/async-search/AsyncSearch';
import { useNavigate, useParams } from 'react-router-dom';
import validateUuId from '../../util/validateUuid';
import moment from 'moment';
import IOrderCustomerFormProps from './interfaces/IOrderCustomerFormProps';
import { useAuth } from '../../hooks/auth';
import { ISeller } from '../../interfaces/ISeller';
import { useSellersService } from '../../services/useSellersService';
import { IBranchOffice } from '../../interfaces/IBranchOffice';
import { useOrderParamsService } from '../../services/useOrderParamsService';
import { Button } from '@mui/material';
import CustomerDialogForm from './CustomerDialogForm';

const OrderCustomerForm = forwardRef<IOrderCustomerFormProps, {}>((props, ref) => {
  useImperativeHandle(ref, () => ({
    listOrder,
    async save() {
      return handleSave();
    },
  }));

  const [loading, setLoading] = useState(false);
  const [customers, setCustomers] = React.useState<ICustomer[]>([]);
  const [customerOptions, setCustomerOptions] = React.useState<IOption[]>([]);
  const [customerOption, setCustomerOption] = React.useState<IOption | null>(null);
  const [isCustomerDialogOpen, setIsCustomerDialogOpen] = useState(false);

  const [branchOffice, setBranchOffice] = React.useState<IBranchOffice | null>(null);

  const [errors, setErrors] = useState<IFormError | null>();
  const [perPage] = useState(50);
  const [currentPage] = useState(0);
  const [orderDirection] = useState<'asc' | 'desc'>('asc');
  const [orderField] = useState<string>('corporateName');
  const [customer, setCustomer] = useState<ICustomer | null>(null);
  const [seller, setSeller] = useState<ISeller | null>(null);
  const [sellers, setSellers] = React.useState<ISeller[]>([]);
  const [errorsSeller, setErrorsSeller] = useState<IFormError | null>();
  const [sellerOptions, setSellerOptions] = React.useState<IOption[]>([]);
  const [sellerOption, setSellerOption] = React.useState<IOption | null>(null);
  const [order, setOrder] = useState<IOrder | null>(null);

  const timeout = useRef<any>(0);

  const customerService = useCustomerService();
  const sellerService = useSellersService();
  const orderService = useOrderService();
  const orderParamsService = useOrderParamsService();
  const toastr = useToastr();
  const validation = useValidateSchema();
  const navigate = useNavigate();
  const params = useParams();
  const [enableProspect, setEnableProspect] = useState(false);

  const { state } = useAuth();

  React.useEffect(() => {
    const id = params.id;

    if (id && validateUuId(id)) {
      listOrder(id);
    }
  }, []);

  React.useEffect(() => {
    listOrderParams();
    listCustomers();
    listSellers();
  }, [customer, seller, params.id]);

  const adminResource = state.resources.find(value => value === 'administrador-pedido-web');

  const schemaSeller = yup.object({
    name: yup.string().required('Campo obrigatório').min(1, 'Campo obrigatório.'),
  });

  const schema = yup.object({
    corporateDocument: yup.string().required('Campo obrigatório').min(1, 'Campo obrigatório.'),
  });

  const handleSave = async () => {
    if (params.id && validateUuId(params.id)) {
      return await updateOrder();
    } else {
      return await createOrder();
    }
  };

  const listOrderParams = async () => {
    await orderParamsService
      .list()
      .then(param => {
        setBranchOffice(param?.defaultBranchOffice || null);
        setEnableProspect(param.enableProspect || false);
      })
      .catch(error => {
        toastr.error(error.message);
      });
  };

  const updateOrder = async () => {
    const result = await validation.validate(schema, customer || {});
    setErrors(result.errors);

    const resultSeller = await validation.validate(schemaSeller, seller || {});
    setErrorsSeller(resultSeller.errors);

    if (!adminResource) {
      resultSeller.isValid = true;
    }

    if (result.isValid && resultSeller.isValid) {
      const sellerId = customer?.sellers?.find(() => true)?.id || state.seller?.id || seller?.id;

      if (!branchOffice?.id) {
        toastr.error('Não foi localizada uma filial para incluão do pedido.');
        return false;
      }

      if (!sellerId) {
        toastr.error('Cliente não possui um representante vinculado! Por favor revise os cadastros.');
        return false;
      }

      const order: IOrder = {
        id: params.id,
        branchOfficeId: branchOffice.id,
        orderNumber: '0',
        orderTypeId: 1, // pedido
        customerId: `${customer?.id}`,
        sellerId,
      };

      setLoading(true);
      return await orderService
        .updateOrder(`${order.id}`, order)
        .then(result => {
          return true;
        })
        .catch(error => {
          toastr.error(error.message);
          return false;
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      if (adminResource) {
        return result.isValid && resultSeller.isValid;
      }

      return result.isValid;
    }
  };

  const createOrder = async () => {
    const result = await validation.validate(schema, customer || {});
    setErrors(result.errors);

    const resultSeller = await validation.validate(schemaSeller, seller || {});
    setErrorsSeller(resultSeller.errors);

    if (!adminResource) {
      resultSeller.isValid = true;
    }

    if (result.isValid && resultSeller.isValid) {
      const sellerId = customer?.sellers?.find(() => true)?.id || state.seller?.id || seller?.id;
      if (!branchOffice?.id) {
        toastr.error('Não foi localizada uma filial para incluão do pedido.');
        return false;
      }

      if (!sellerId) {
        toastr.error('Cliente não possui um representante vinculado! Por favor revise os cadastros.');
        return false;
      }

      const order: IOrder = {
        orderDate: moment().format('yyyy-MM-DD'),
        branchOfficeId: branchOffice.id,
        orderNumber: '0',
        orderTypeId: 1, // pedido
        orderStatusId: 1, // orçamento
        customerId: `${customer?.id}`,
        sellerId,
      };

      setLoading(true);
      return await orderService
        .createOrder(order)
        .then(result => {
          navigate(`/order/steper/${result.id}/edit`);
          return true;
        })
        .catch(error => {
          toastr.error(error.message);
          return false;
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      if (adminResource) {
        return result.isValid && resultSeller.isValid;
      }

      return result.isValid;
    }
  };

  const listOrder = async (id: string) => {
    setLoading(true);
    await orderService
      .listOrder(id, true)
      .then(result => {
        result && setOrder({ ...result });
        if (result && result.customer && result.seller) {
          setCustomer(result.customer);
          setCustomerOption(customerToOption(result.customer));

          setSeller(result.seller);
          setSellerOption(sellerToOption(result.seller));
        }
      })
      .catch(error => {
        toastr.error(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSetSeller = (option: IOption | null) => {
    if (option) {
      setSellerOption(option);
      const selected = sellers.find(customer => {
        return customer.id === option.value;
      });
      if (selected?.code) {
        setSeller(selected);
      } else {
        setSeller(null);
      }
    }
  };

  const sellerToOption = (seller: ISeller): IOption => {
    return {
      value: `${seller.id}`,
      description: `${seller.code} ${seller.name}`,
    };
  };

  const listSellers = async (filter = '') => {
    clearInterval(timeout.current);
    timeout.current = setTimeout(async () => {
      setLoading(true);
      const url = `perPage=100&currentPage=1&orderBy=name&orderDirection=asc&filter=${filter}`;

      await sellerService
        .listDynamically(url)
        .then(result => {
          if (result) {
            setSellers(result.data);
            const options: IOption[] = result.data.map(sellerToOption);
            const isSallerOnArray = options.find(opt => opt.value === seller?.id);
            if (seller?.id && !isSallerOnArray) {
              options.push(sellerToOption(seller));
            }
            setSellerOptions(options);
          } else {
            setSellerOptions([]);
          }
        })
        .catch(error => {
          setSellerOptions([]);
          toastr.error(error.message);
        })
        .finally(() => {
          setLoading(false);
        });
    }, timeout.current);
  };

  const handleSetCustomer = (option: IOption | null) => {
    if (option) {
      setCustomerOption(option);
      const selected = customers.find(customer => {
        return customer.id === option.value;
      });
      if (selected?.id) {
        setCustomer(selected);
      } else {
        setCustomer(null);
      }
    }
  };

  const customerToOption = (customer: ICustomer): IOption => {
    let description = `${customer.code} ${customer.corporateName} ${customer.corporateDocument}`;
    if (customer.isProspect) {
      description = `(Prospect) ${customer.corporateName} ${customer.corporateDocument}`;
    }
    return {
      value: `${customer.id}`,
      description,
    };
  };

  const listCustomers = async (filter = '') => {
    clearInterval(timeout.current);
    timeout.current = setTimeout(async () => {
      setLoading(true);
      const url = `perPage=${perPage}&currentPage=${
        currentPage + 1
      }&orderBy=${orderField}&orderDirection=${orderDirection}&filter=${filter}`;

      await customerService
        .listDynamically(url)
        .then(result => {
          if (result) {
            setCustomers(result.data);
            const options: IOption[] = result.data.map(customerToOption);
            const isCustomerOnArray = options.find(opt => opt.value === customer?.id);
            if (customer?.id && !isCustomerOnArray) {
              options.push(customerToOption(customer));
            }
            setCustomerOptions(options);
          } else {
            setCustomerOptions([]);
          }
        })
        .catch(error => {
          setCustomerOptions([]);
          toastr.error(error.message);
        })
        .finally(() => {
          setLoading(false);
        });
    }, timeout.current);
  };

  const customerCreated = (customerParam: ICustomer) => {
    setCustomer({ ...customerParam });
    setCustomerOption(customerToOption(customerParam));
    setIsCustomerDialogOpen(false);
  };

  return (
    <>
      {adminResource ? (
        <>
          <AsyncSearch
            options={sellerOptions}
            setOptions={setSellerOptions}
            option={sellerOption}
            setOption={handleSetSeller}
            asyncSearch={listSellers}
            loading={loading}
            error={Boolean(errorsSeller?.name)}
            errorMessage={errorsSeller?.name || null}
            label="Representantes"
            disabled={!!order?.totalValue && order?.totalValue !== 0}
          />
          <br />
        </>
      ) : (
        ''
      )}

      <AsyncSearch
        disabled={!!order?.totalValue && order?.totalValue !== 0}
        options={customerOptions}
        setOptions={setCustomerOptions}
        option={customerOption}
        setOption={handleSetCustomer}
        asyncSearch={listCustomers}
        loading={loading}
        error={Boolean(errors?.corporateDocument)}
        errorMessage={errors?.corporateDocument || null}
        label="Cliente"
      />
      <br />
      {enableProspect && !customer?.isProspect && (
        <Button style={{ textTransform: 'none' }} onClick={() => setIsCustomerDialogOpen(true)}>
          Adicionar Prospect
        </Button>
      )}
      {enableProspect && customer?.isProspect && (
        <Button style={{ textTransform: 'none' }} onClick={() => setIsCustomerDialogOpen(true)}>
          Editar Prospect
        </Button>
      )}
      <CustomerDialogForm
        open={isCustomerDialogOpen}
        onClose={() => setIsCustomerDialogOpen(false)}
        customerCreated={customerCreated}
        customerId={customer?.isProspect ? customer?.id : undefined}
      />
    </>
  );
});

export default OrderCustomerForm;
