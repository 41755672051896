import React, { useEffect, useState } from 'react';
import IPage from '../../interfaces/IPage';
import { TitleContainer } from '../../components/title-container/TitleContainer';
import { PageCard } from '../../components/page-card/PageCard';
import { Form } from '../../components/form/Form';
import {
  Autocomplete,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import IOrderParam from '../../interfaces/IOrderParam';
import { IBranchOffice } from '../../interfaces/IBranchOffice';
import { useBranchOfficeService } from '../../services/useBranchOfficeService';
import { Button } from '../../components/button/Button';
import { useOrderParamsService } from '../../services/useOrderParamsService';
import { useToastr } from '../../hooks/useToastr';
import { BackdropCustom } from '../../components/backdrop/Backdrop';

const OrderParamsForm: React.FC<IPage> = ({ title }) => {
  window.document.title = title;

  const [loading, setLoading] = useState(false);
  const [orderParam, setOrderParam] = useState<IOrderParam>({});
  const [branchOffices, setBranchOffices] = useState<IBranchOffice[]>([]);

  const toastr = useToastr();
  const branchOfficeService = useBranchOfficeService();
  const orderParamsService = useOrderParamsService();

  const listOrderParam = async () => {
    setLoading(true);
    orderParamsService
      .list()
      .then(param => {
        if (!param.comments) {
          param.comments = '';
        }

        if (!param.reportDefaultFormat) {
          param.reportDefaultFormat = '';
        }

        setOrderParam(param);
      })
      .catch(error => {
        toastr.error(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const listBranchOffice = async () => {
    setLoading(true);
    await branchOfficeService
      .listAll(`perPage=100&currentPage=1&orderBy=name&orderDirection=ASC`)
      .then(result => {
        setBranchOffices(result.data);
      })
      .catch(error => {
        toastr.error(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const update = async () => {
    setLoading(true);

    if (!orderParam) {
      return;
    }

    const data = {
      id: orderParam.id,
      carrierRequired: orderParam.carrierRequired,
      comments: orderParam.comments,
      paymentFormRequired: orderParam.paymentFormRequired,
      defaultBranchOfficeId: orderParam.defaultBranchOfficeId,
      reportDefaultFormat: orderParam.reportDefaultFormat,
      enableProspect: orderParam.enableProspect,
    };

    orderParamsService
      .update(`${orderParam?.id}`, data)
      .then(() => {
        toastr.success('Dados atualizados com sucesso');
      })
      .catch(error => {
        toastr.error(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    listOrderParam();
    listBranchOffice();
  }, []);

  return (
    <>
      <TitleContainer>
        <h1>Parâmetros para Pedidos</h1>
      </TitleContainer>
      <PageCard>
        <Form>
          {loading && <BackdropCustom />}
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Autocomplete
                disablePortal
                options={branchOffices}
                size="small"
                getOptionLabel={option => `${option.corporateDocument} ${option.name}`}
                value={orderParam.defaultBranchOffice || null}
                renderInput={params => <TextField {...params} label="Filial padrão para pedidos" fullWidth />}
                isOptionEqualToValue={(option, value) => {
                  return option?.name === value?.name || true;
                }}
                onChange={(e, newValue) => {
                  const branchOffice = newValue as IBranchOffice;
                  setOrderParam({
                    ...orderParam,
                    defaultBranchOffice: branchOffice,
                    defaultBranchOfficeId: branchOffice.id,
                  });
                }}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <TextField
                label="Observação para Pedido"
                value={`${orderParam?.comments}`}
                fullWidth
                multiline
                rows={4}
                onChange={e => setOrderParam({ ...orderParam, comments: e.target.value })}
              />
            </Grid>
            <Grid item xs={12} md={6} sm={6}>
              <FormControlLabel
                value={orderParam.enableProspect}
                label="Habilitar Cadastro de Cliente Prospect"
                control={
                  <Checkbox
                    onChange={e => setOrderParam({ ...orderParam, enableProspect: e.target.checked })}
                    checked={Boolean(orderParam.enableProspect)}
                  />
                }
              />
            </Grid>

            <Grid item xs={12} md={12} sm={12} sx={{ marginBottom: '-16px' }}>
              <span>Selecione os campos que devem ser obrigatórios:</span>
            </Grid>

            <Grid item xs={12} md={6} sm={6}>
              <FormControlLabel
                label="Forma de Pagamento"
                control={
                  <Checkbox
                    onChange={e => setOrderParam({ ...orderParam, paymentFormRequired: e.target.checked })}
                    checked={Boolean(orderParam.paymentFormRequired)}
                  />
                }
              />
            </Grid>

            <Grid item xs={12} md={6} sm={6}>
              <FormControlLabel
                value={orderParam.carrierRequired}
                label="Transportadora"
                control={
                  <Checkbox
                    onChange={e => setOrderParam({ ...orderParam, carrierRequired: e.target.checked })}
                    checked={Boolean(orderParam.carrierRequired)}
                  />
                }
              />
            </Grid>

            <Grid item md={6} sm={12} xs={12}>
              <FormControl size="small" sx={{ width: '100%' }}>
                <InputLabel id="report-default-format-select-label">Formato padrão do relatório</InputLabel>
                <Select
                  labelId="report-default-format-select-label"
                  id="report-default-format-select"
                  value={orderParam.reportDefaultFormat || ''}
                  label="Formato padrão do relatório"
                  onChange={e => {
                    setOrderParam({ ...orderParam, reportDefaultFormat: e.target.value });
                  }}
                  size="small"
                >
                  <MenuItem value="">
                    <em>Nenhum</em>
                  </MenuItem>
                  <MenuItem value="HTML">HTML</MenuItem>
                  <MenuItem value="PDF">PDF</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', marginTop: '16px' }}>
            <Button onClick={update} variant="contained" loading={loading || false}>
              Salvar
            </Button>
          </div>
        </Form>
      </PageCard>
    </>
  );
};

export default OrderParamsForm;
