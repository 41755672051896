import {
  FormControl,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { AiTwotoneEdit } from 'react-icons/ai';
import dayjs from 'dayjs';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { MuiColorInput } from 'mui-color-input';

import { TitleContainer } from '../../components/title-container/TitleContainer';
import IPage from '../../interfaces/IPage';
import { IconButtonMui, ImageContainer } from './ImageParamsList.styled';
import { useCallback, useEffect, useState } from 'react';
import CustomTableSortLabel from '../../components/table/CustomTableSortLabel/CustomTableSortLabel';
import { IImageParams } from '../../interfaces/IImageParams';
import { useImageParamsService } from '../../services/useImageParamsService';
import { useToastr } from '../../hooks/useToastr';
import CustomTablePagination from '../../components/table/CustomTablePagination/CustomTablePagination';
import { PageCard } from '../../components/page-card/PageCard';
import { ButtonGroup } from '../../components/button-group/ButtonGroup';
import { Form } from '../../components/form/Form';
import { useColorService } from '../../services/useColorService';
import { BackdropCustom } from '../../components/backdrop/Backdrop';
import { Button } from '../../components/button/Button';

const ImageParamsList: React.FC<IPage> = ({ title }) => {
  window.document.title = title;

  const colorService = useColorService();

  const [imageParams, setImageParams] = useState<IImageParams[]>([]);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [orderDirection, setOrderDirection] = useState<'asc' | 'desc'>('asc');
  const [orderField, setOrderField] = useState<string>('initialDate');
  const [loading, setLoading] = useState(false);

  const [mainColor, setMainColor] = useState('#088671');
  const [hoverColor, setHoverColor] = useState('#08816d');
  const [fontColor, setFontColor] = useState('#FFF');
  const [iFrame, setIFrame] = useState('');

  const { listAll } = useImageParamsService();

  const toastr = useToastr();

  const navigate = useNavigate();

  const handleToImageParams = useCallback(
    (id: string | undefined) => {
      !!id ? navigate(`/image-params/${id}`) : navigate('/image-params');
    },
    [navigate],
  );

  const handleViewImage = useCallback((imageUrl: string) => {
    if (imageUrl.length > 15) {
      window.open(imageUrl, '_blank');
    }
  }, []);

  const handleListImageParams = useCallback(
    async (perPage: number, currentPage: number, orderField: string, orderDirection: 'asc' | 'desc') => {
      setLoading(true);
      await listAll(
        `perPage=${perPage}&currentPage=${currentPage + 1}&orderBy=${orderField}&orderDirection=${orderDirection}`,
      )
        .then(response => {
          const imageParams = response.data;

          if (imageParams.length > 0) {
            setImageParams(response.data);
            setTotalRows(response.totalRows);
            setLoading(false);
          } else {
            setImageParams([]);
            setTotalRows(0);
            setPerPage(10);
            setCurrentPage(0);
            setLoading(false);
          }
        })
        .catch(error => {
          toastr.error(error?.message || 'Contate a equipe de suporte');
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [],
  );

  const handleListColors = useCallback(async () => {
    await colorService.listColor().then(colors => {
      setMainColor(colors.mainColor);
      setHoverColor(colors.hoverColor);
      setFontColor(colors.fontColor);
      setIFrame(colors.iFrame || '');
    });
  }, []);

  const handleUpdateColors = useCallback(async () => {
    console.log(iFrame);

    const color = { mainColor, hoverColor, fontColor, iFrame };
    setLoading(true);
    await colorService.updateColor(color);
    setLoading(false);
  }, [mainColor, hoverColor, fontColor, iFrame]);

  useEffect(() => {
    handleListImageParams(perPage, currentPage, orderField, orderDirection);
  }, [perPage, currentPage, orderField, orderDirection, handleListImageParams]);

  const handleChangeMainColor = (color: string) => {
    setMainColor(color);
  };

  const handleChangeHoverColor = (color: string) => {
    setHoverColor(color);
  };

  const handleChangeFontColor = (color: string) => {
    setFontColor(color);
  };

  useEffect(() => {
    handleListColors();
  }, []);

  return (
    <>
      <TitleContainer>
        <h1>Imagens</h1>
      </TitleContainer>
      <PageCard>
        <ButtonGroup>
          <IconButtonMui
            onClick={() => handleToImageParams(undefined)}
            size="small"
            color="primary"
            style={{ alignItems: 'flex-end' }}
          >
            <AddOutlinedIcon />
          </IconButtonMui>
        </ButtonGroup>

        <TableContainer sx={{ marginTop: '2px', paddingLeft: '8px', paddingRight: '8px' }}>
          {loading && <BackdropCustom />}
          <Table>
            <TableHead>
              <TableRow
                sx={{
                  '&:last-child td, &:last-child th': {
                    borderBottom: 1,
                    borderColor: '#E5E5E5',
                  },
                }}
              >
                <CustomTableSortLabel
                  perPage={perPage}
                  currentPage={currentPage}
                  orderField={orderField}
                  field="imageType.description"
                  label="Tipo de Imagem"
                  handleRequest={handleListImageParams}
                  orderDirection={orderDirection}
                  setField={setOrderField}
                  disableOrder
                  setDirection={setOrderDirection}
                />
                <CustomTableSortLabel
                  perPage={perPage}
                  currentPage={currentPage}
                  orderField={orderField}
                  field="initialDate"
                  label="Data Inicial"
                  handleRequest={handleListImageParams}
                  orderDirection={orderDirection}
                  setField={setOrderField}
                  setDirection={setOrderDirection}
                />
                <CustomTableSortLabel
                  perPage={perPage}
                  currentPage={currentPage}
                  orderField={orderField}
                  field="finalDate"
                  label="Data Final"
                  handleRequest={handleListImageParams}
                  orderDirection={orderDirection}
                  setField={setOrderField}
                  setDirection={setOrderDirection}
                />
                <CustomTableSortLabel
                  perPage={perPage}
                  currentPage={currentPage}
                  orderField={orderField}
                  field="image"
                  label="Imagem"
                  handleRequest={handleListImageParams}
                  orderDirection={orderDirection}
                  disableOrder
                  setField={setOrderField}
                  setDirection={setOrderDirection}
                />
                <TableCell size="small">Ações</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {imageParams &&
                imageParams.map(imageParam => {
                  return (
                    <TableRow
                      key={imageParam.id}
                      sx={{
                        '&:last-child td, &:last-child th': {
                          border: 0,
                        },
                      }}
                    >
                      <TableCell size="small">{imageParam?.imageType?.description}</TableCell>
                      <TableCell size="small">{dayjs(imageParam?.initialDate).format('DD/MM/YYYY')}</TableCell>
                      <TableCell size="small">{dayjs(imageParam?.finalDate).format('DD/MM/YYYY')}</TableCell>
                      <TableCell size="small">
                        {imageParam.image ? (
                          <ImageContainer>
                            <img
                              src={imageParam.image}
                              alt="imagereport"
                              onClick={() => handleViewImage(imageParam.image)}
                            />
                          </ImageContainer>
                        ) : (
                          ''
                        )}
                      </TableCell>
                      <TableCell size="small">
                        <Tooltip title="Editar" placement="top">
                          <IconButton disabled={loading} onClick={() => handleToImageParams(imageParam.id)}>
                            <AiTwotoneEdit style={{ fontSize: '15px' }} />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
          <CustomTablePagination
            totalRows={totalRows}
            currentPage={currentPage}
            perPage={perPage}
            handleRequest={handleListImageParams}
            setPerPage={setPerPage}
            setCurrentPage={setCurrentPage}
            orderField={orderField}
            orderDirection={orderDirection}
          />
        </TableContainer>
      </PageCard>

      <TitleContainer>
        <h1 style={{ marginTop: '24px' }}>Cores da aplicação</h1>
      </TitleContainer>
      <PageCard>
        <Form>
          <Grid container spacing={3} sx={{ marginBottom: '24px' }}>
            <Grid item xs={12} md={6} sm={6}>
              <FormControl fullWidth size="small">
                <MuiColorInput
                  value={`${mainColor}`}
                  onChange={handleChangeMainColor}
                  format={'hex'}
                  variant="outlined"
                  style={{ width: '100%' }}
                  label="Cor do Principal"
                  size="small"
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6} sm={6}>
              <FormControl fullWidth size="small">
                <MuiColorInput
                  value={`${hoverColor}`}
                  onChange={handleChangeHoverColor}
                  format={'hex'}
                  variant="outlined"
                  style={{ width: '100%' }}
                  label="Cor do Hover"
                  size="small"
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6} sm={6}>
              <FormControl fullWidth size="small">
                <MuiColorInput
                  value={`${fontColor}`}
                  onChange={handleChangeFontColor}
                  format={'hex'}
                  variant="outlined"
                  style={{ width: '100%' }}
                  label="Cor da Fonte"
                  size="small"
                />
              </FormControl>
            </Grid>
          </Grid>

          <Grid item xs={12} md={4}>
            <TextField label="iFrame" size="small" value={iFrame} fullWidth onChange={e => setIFrame(e.target.value)} />
          </Grid>
        </Form>

        <ButtonGroup>
          <Button variant="contained" onClick={handleUpdateColors}>
            Confirmar
          </Button>
        </ButtonGroup>
      </PageCard>
    </>
  );
};

export { ImageParamsList };
