import React, { KeyboardEvent, MouseEvent, useState } from 'react';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import { useNavigate } from 'react-router-dom';

import MenuIcon from '@mui/icons-material/Menu';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import SettingsIcon from '@mui/icons-material/Settings';
import LocalMallIcon from '@mui/icons-material/LocalMall';
import { ButtonMenu, BoxMui, ButtonMui, ListItemIconMui, SubItemMenu } from './AppMenu.styled';
import { useAuth } from '../../hooks/auth';

const AppMenu: React.FC = () => {
  const [stateMenu, setStateMenu] = useState({
    left: false,
  });

  const [settingsOpen, setSettingsOpen] = useState(false);
  const [ordersOpen, setOrdersOpen] = useState(false);
  const [registrationsOpen, setRegistrationsOpen] = useState(false);

  const { state } = useAuth();
  const navigate = useNavigate();

  const toggleDrawer = (open: boolean) => (event: KeyboardEvent | MouseEvent) => {
    if (
      event &&
      event.type === 'keydown' &&
      ((event as KeyboardEvent).key === 'Tab' || (event as KeyboardEvent).key === 'Shift')
    ) {
      return;
    }

    setStateMenu({ left: open });
  };

  return (
    <div>
      <ButtonMenu onClick={toggleDrawer(true)}>
        <MenuIcon fontSize="large" />
      </ButtonMenu>

      <SwipeableDrawer anchor="left" open={stateMenu['left']} onClose={toggleDrawer(false)} onOpen={toggleDrawer(true)}>
        <BoxMui role="presentation">
          <List>
            <ListItem key="home" disablePadding>
              <ListItemButton
                onClick={() => {
                  setStateMenu({ left: false });
                  navigate('/');
                }}
              >
                <ListItemIconMui>
                  <HomeOutlinedIcon />
                </ListItemIconMui>
                <ListItemText primary="Início" />
              </ListItemButton>
            </ListItem>

            {state.resources.some(
              item =>
                item === 'configurações-parametros-pedido-web' ||
                item === 'configurações-imagens-pedido-web' ||
                item === 'configurações-status-pedido-web' ||
                item === 'configurações-tipos-pedido-web' ||
                item === 'configurações-aplicações-pedido-web',
            ) && (
              <>
                <ListItem key="settings" disablePadding onClick={() => setSettingsOpen(!settingsOpen)}>
                  <ListItemButton>
                    <ListItemIconMui>
                      <SettingsIcon />
                    </ListItemIconMui>
                    <ListItemText primary="Configurações" />
                  </ListItemButton>
                </ListItem>

                {settingsOpen && (
                  <SubItemMenu>
                    {state.resources.some(item => item === 'configurações-parametros-pedido-web') && (
                      <ButtonMui
                        variant="text"
                        disableElevation
                        onClick={() => {
                          setStateMenu({ left: false });
                          navigate('order-params');
                        }}
                      >
                        <span>Parâmetros do Pedido</span>
                      </ButtonMui>
                    )}

                    {state.resources.some(item => item === 'configurações-imagens-pedido-web') && (
                      <ButtonMui
                        variant="text"
                        disableElevation
                        onClick={() => {
                          setStateMenu({ left: false });
                          navigate('image-params-list');
                        }}
                      >
                        <span>Imagens e cores</span>
                      </ButtonMui>
                    )}

                    {state.resources.some(item => item === 'configurações-aplicações-pedido-web') && (
                      <ButtonMui
                        variant="text"
                        disableElevation
                        onClick={() => {
                          setStateMenu({ left: false });
                          navigate('configure-applications');
                        }}
                      >
                        <span>Configuração de aplicações</span>
                      </ButtonMui>
                    )}

                    {state.resources.some(item => item === 'configurações-status-pedido-web') && (
                      <ButtonMui
                        variant="text"
                        disableElevation
                        onClick={() => {
                          setStateMenu({ left: false });
                          navigate('orders-status-list');
                        }}
                      >
                        <span>Status de Pedidos</span>
                      </ButtonMui>
                    )}

                    {state.resources.some(item => item === 'configurações-tipos-pedido-web') && (
                      <ButtonMui
                        variant="text"
                        disableElevation
                        onClick={() => {
                          setStateMenu({ left: false });
                          navigate('order-types-list');
                        }}
                      >
                        <span>Tipos de Pedido</span>
                      </ButtonMui>
                    )}
                    {state.resources.some(item => item === 'configurações-tipos-pedido-web') && (
                      <ButtonMui
                        variant="text"
                        disableElevation
                        onClick={() => {
                          setStateMenu({ left: false });
                          navigate('field-configurations-list');
                        }}
                      >
                        <span>Configurações de Campos</span>
                      </ButtonMui>
                    )}
                  </SubItemMenu>
                )}
              </>
            )}

            {state.resources.some(
              item =>
                item === 'consulta-produtos-pedido-web' ||
                item === 'consulta-unidades-medida-pedido-web' ||
                item === 'consulta-representantes-pedido-web' ||
                item === 'consulta-clientes-pedido-web' ||
                item === 'consulta-table-preco-pedido-web',
            ) && (
              <>
                <ListItem key="registrations" disablePadding onClick={() => setRegistrationsOpen(!registrationsOpen)}>
                  <ListItemButton>
                    <ListItemIconMui>
                      <AppRegistrationIcon />
                    </ListItemIconMui>
                    <ListItemText primary="Consultas" />
                  </ListItemButton>
                </ListItem>

                {registrationsOpen && (
                  <SubItemMenu>
                    {state.resources.some(item => item === 'consulta-produtos-pedido-web') && (
                      <ButtonMui
                        variant="text"
                        disableElevation
                        onClick={() => {
                          setStateMenu({ left: false });
                          navigate('products-list');
                        }}
                      >
                        <span>Produtos</span>
                      </ButtonMui>
                    )}

                    {state.resources.some(item => item === 'consulta-unidades-medida-pedido-web') && (
                      <ButtonMui
                        variant="text"
                        disableElevation
                        onClick={() => {
                          setStateMenu({ left: false });
                          navigate('measurement-unit');
                        }}
                      >
                        <span>Unidades de Medida</span>
                      </ButtonMui>
                    )}

                    {state.resources.some(item => item === 'consulta-representantes-pedido-web') && (
                      <ButtonMui
                        variant="text"
                        disableElevation
                        onClick={() => {
                          setStateMenu({ left: false });
                          navigate('sellers-list');
                        }}
                      >
                        <span>Representantes</span>
                      </ButtonMui>
                    )}

                    {state.resources.some(item => item === 'consulta-clientes-pedido-web') && (
                      <ButtonMui
                        variant="text"
                        disableElevation
                        onClick={() => {
                          setStateMenu({ left: false });
                          navigate('customers-list');
                        }}
                      >
                        <span>Clientes</span>
                      </ButtonMui>
                    )}

                    {state.resources.some(item => item === 'consulta-table-preco-pedido-web') && (
                      <ButtonMui
                        variant="text"
                        disableElevation
                        onClick={() => {
                          setStateMenu({ left: false });
                          navigate('price-tables-list');
                        }}
                      >
                        <span>Tabelas de Preços</span>
                      </ButtonMui>
                    )}
                  </SubItemMenu>
                )}
              </>
            )}

            {state.resources.some(
              item => item === 'consulta-pedidos-pedido-web' || item === 'editar-pedidos-pedido-web',
            ) && (
              <>
                <ListItem key="orders" disablePadding onClick={() => setOrdersOpen(!ordersOpen)}>
                  <ListItemButton>
                    <ListItemIconMui>
                      <LocalMallIcon />
                    </ListItemIconMui>
                    <ListItemText primary="Pedidos" />
                  </ListItemButton>
                </ListItem>

                {ordersOpen && (
                  <SubItemMenu>
                    {state.resources.some(item => item === 'editar-pedidos-pedido-web') && (
                      <ButtonMui
                        variant="text"
                        disableElevation
                        onClick={() => {
                          setStateMenu({ left: false });
                          navigate('order/steper/0/new');
                        }}
                      >
                        <span>Adicionar novo pedido</span>
                      </ButtonMui>
                    )}

                    {state.resources.some(item => item === 'consulta-pedidos-pedido-web') && (
                      <ButtonMui
                        variant="text"
                        disableElevation
                        onClick={() => {
                          setStateMenu({ left: false });
                          navigate('orders-list');
                        }}
                      >
                        <span>Consultar pedidos</span>
                      </ButtonMui>
                    )}
                  </SubItemMenu>
                )}
              </>
            )}
          </List>
        </BoxMui>
      </SwipeableDrawer>
    </div>
  );
};

export { AppMenu };
